import React, {useContext} from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import {Quote, freshTokensObject} from 'Contexts/static_options/Classes.js'


export default function NewSOSameParametersButton({}){
    const {currentServiceOrder, setCurrentServiceOrder, user, initialTarget} = useContext(QuoteMasterContext);
    const newSOSameParameters = () => {
        currentServiceOrder.providers.forEach(provider => {
            if(provider.quoteViaLink){
                const quoteViaLink = provider.itemQuotes.filter(itemQuote => itemQuote.stage===1)
                const itemQuotesIndexes = provider.itemQuotes
                    .map((itemQuote, index) => (itemQuote.stage === 1 ? index : -1)) // Map to indexes or -1 for non-matching
                    .filter(index => index !== -1); // Filter out -1 values
                provider.providerBestQuote = {
                    price: quoteViaLink.reduce((acc, itemQuote) => itemQuote.price+acc, 0),
                    rate: '',
                    shortestAvailability: quoteViaLink.reduce((acc, itemQuote) => itemQuote.shortestAvailability.relativeDays > acc.relativeDays ? itemQuote.shortestAvailability.relativeDays : acc, quoteViaLink[0].shortestAvailability),
                    brand: quoteViaLink.reduce((acc, itemQuote) => itemQuote.brand.tier > acc.tier ? {tier:itemQuote.brand.tier} : acc, {tier: 0}),
                    itemQuotesIndexes: itemQuotesIndexes,
                    completed : quoteViaLink.reduce((acc, itemQuote) => itemQuote.completed && acc, true),
                    valid: quoteViaLink.reduce((acc, itemQuote) => itemQuote.valid && acc, true),
                    quoteReceivedAtTime : provider.providerBestQuote.quoteReceivedAtTime,
                    mobileService: provider.providerBestQuote.mobileService,
                    bookingTime: provider.providerBestQuote.bookingTime,
                    affiliateId: provider.affiliateId,
                    dateOptions: provider.providerBestQuote.dateOptions
                }
                provider.itemQuotes = provider.itemQuotes.filter(itemQuote => itemQuote.stage===1)
            } else{
                provider.itemQuotes = []
                provider.providerBestQuote = new Quote();
            }
            provider.conversation = [];
            provider.reasignedBrands= []; // this is a list of brands to help in the conversation. The conversation goes in a way, but we need to use specific names for the brands
            provider.timeOflastMessageSentToProvider= null; // this is the time for the next follow up
            provider.counterOfferSent= false;
            provider.waitingResponse= false;
            provider.reasonsForProviderToNotBeConsidered= [];
            provider.whatsappSource = {number: '', countryCode: '+55', areaCode: ''};
        });       
        setCurrentServiceOrder({
            ...currentServiceOrder, 
            currentStage: 'notInitiated', 
            previousStage: '', // this is the last stage of the negotiation
            serviceOrderId: '',
            currentTarget: {...initialTarget, price: currentServiceOrder.currentTarget.price, rate: currentServiceOrder.currentTarget.rate},
            user: user,
            timeLastUpdate: '',
            closeReason: '',
            stageStartTime: [{stage: 'notInitiated', time: ''},{stage: 1, time: ''}, {stage: 2, time: ''}, {stage: 3, time: ''}, {stage: 'confirmToClose', time: ''}, {stage: 'confirmFifoAndTime', time: ''}, {stage: 'confirmDates', time: ''}, {stage: 'fallbackToHuman', time: ''}, {stage: 'closed', time: ''}], // this is the time when each stage started
            tokens: [],
            notes: ''
        });
    }

    return(
        <GlobalButton 
        onClick={()=>newSOSameParameters()}
        label={'Nova Ordem de Serviço - mesmos parâmetros'}
        type={'primary'}
        width={'100%'}
    />
    )
}