
import React, { useEffect, useState, useContext, useRef } from 'react';

import styles from './AdminPanel.module.css';

import {getUsersList, changeUserStatus, deleteUserInDB, getNgrokURLFromServer, updateNgrokURLInServer} from '../ServerCalls/serverCalls.js';


import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js'
import ConfirmationModal from 'GlobalComponents/ConfirmationModal/ConfirmationModal.js';
import NewUserModal from './NewUserModal.js';
import WaitingProgressLine from 'GlobalComponents/WaitingProgressLine/WaitingProgressLine.js';

import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import Icon from 'GlobalComponents/Icon/Icon.js';


export default function AdminPanel({userList, setUserList, setIsAdminPanelOpen }) {
    const {loading, setLoading} = useContext(QuoteMasterContext);
    const [userToDelete, setUserToDelete] = useState('');
    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
    const [isNgrokURLOpen, setIsNgrokURLOpen] = useState(false);

    useEffect(() => {
        if(userList.length === 0){
            updateUserList();
        }
    }, []);

    async function updateUserList() {
        setLoading(true);
        const response = await getUsersList();
        if (response === 'failed') {
            console.log('Failed to get user list');
            return;
        }
        setUserList(response.users);
        setLoading(false);
    }

    const deleteUser = async () => {
        try{
            setLoading(true);
            const response = await deleteUserInDB(userToDelete.userEmail);
            if (response !== 'User deleted successfully'){
                alert('Error in deleting user:', response);
                return;
            }
            setUserList(prev=>prev.filter(user=>user.userEmail!==userToDelete.userEmail));
            setUserToDelete('');
            setLoading(false);
        } catch(error){
            console.error('Error in deleting user:', error);
        }
    }

    const userDeleteConfirmationMessage =
            `Are you sure you want to delete the user ${userToDelete.userEmail}? 
            \n This action cannot be undone.`;

    const userDeleteButtons = [
        {text: 'Cancel', onClick: ()=>setUserToDelete(''), width: 100},
        {text: 'Delete', onClick: deleteUser, width: 100}
    ];

    return (
        <div className={styles.adminPanel}>
            <div className={styles.adminPanelHeader}>
                <div className={styles.adminPanelHeaderButtons}>
                    <GlobalButton
                        onClick={() => setIsAdminPanelOpen(false)}
                        label={'Close Admin Panel'}
                        width={'150px'}
                        type={'secondary'}
                    />
                    <GlobalButton
                        onClick={() => setIsNgrokURLOpen(true)}
                        label={'Update nGrok URL'}
                        width={'150px'}
                        type={'secondary'}
                    />
                </div>
                <div className={styles.adminPanelTitle}>Admin Panel</div>
                <GlobalButton 
                    onClick={updateUserList} 
                    label={
                        <> 
                            <RestorePageOutlinedIcon /> 
                            <span>Atualizar Lista <br/>de Usuários</span>
                        </>
                    }
                    width={'184px'}
                    type={'secondary'}
                />
            </div>
            <UserList userList={userList} setUserList={setUserList} setUserToDelete={setUserToDelete} setIsNewUserModalOpen={setIsNewUserModalOpen}/>
            {loading ? <WaitingProgressLine /> : null}
            {userToDelete ? <ConfirmationModal message={userDeleteConfirmationMessage} buttons={userDeleteButtons}/> : null}
            {isNewUserModalOpen ? <NewUserModal setIsNewUserModalOpen={setIsNewUserModalOpen} setUserList={setUserList}/> : null}
            {isNgrokURLOpen ? <NgrokURLModal setIsNgrokURLOpen={setIsNgrokURLOpen}/> : null}
        </div>

    )
}

function UserList({userList, setUserList, setUserToDelete, setIsNewUserModalOpen }) {
    return (
        <div className={styles.userList}>
            <div className={styles.userListMainRow}>
                <div className={styles.adminPanelTitle}>User List</div>
                <GlobalButton onClick={()=>setIsNewUserModalOpen(true)} label={'Create New User'} width={'184px'} type={'primary'}/>                
            </div>
            <div className={styles.userListHeader}>
                <div className={styles.userEmail}>Email</div>
                <div className={styles.userType}>Type</div>
                <div className={styles.userStatus}>Status</div>
            </div>
            {userList.map(user => (
                <User user={user} key={user.userEmail} setUserList={setUserList} setUserToDelete={setUserToDelete}/>
            ))}
        </div>
    )
}

function User({user, setUserList, setUserToDelete}) {

    function deleteUser() {
        setUserToDelete(user);
    }

    async function handleChangeUserStatus(newStatus) {
        const response = await changeUserStatus(user.userEmail, newStatus);
        if (response === 'failed') {
            console.log(`Failed to change user status to ${newStatus}`);
            return;
        }
        setUserList(prevUserList => prevUserList.map(u => u.userEmail === user.userEmail ? {...u, status: newStatus} : u));
    }

    return (
        <div className={styles.user}>
            <div className={styles.userEmail}>{user.userEmail}</div>
            <div className={styles.userType}>{user.userType}</div>
            <div className={styles.userStatus}>{user.status}</div>
            <GlobalButton 
                onClick={user.status === 'active' ? 
                    ()=>handleChangeUserStatus('inactive')
                    : ()=>handleChangeUserStatus('active') 
                }
                label={user.status === 'active' ? 'Deactivate User' : 'Activate User' }
                width={'160px'}
                type={'secondary'}
            />
            {user.userType === 'master-admin' ? null : Icon({originalIcon: 'deleteUser', size: 24, onClick: deleteUser})}
        </div>
    )
}

function NgrokURLModal({setIsNgrokURLOpen}) {
    const {loading, setLoading} = useContext(QuoteMasterContext);
    const [ngrokURL, setNgrokURL] = useState('');
    const ngrokURLRef = useRef(null);

    useEffect(() => {
        if(!ngrokURLRef.current){
            getNgrokURL();
        }
    }, []);

    async function getNgrokURL() {
        setLoading(true);
        const response = await getNgrokURLFromServer();
        if (response === 'failed') {
            console.log('Failed to get nGrok URL');
            setLoading(false);
            return;
        }
        setNgrokURL(response);
        ngrokURLRef.current = response;
        setLoading(false);
    }

    async function updateNgrokURL() {
        setLoading(true);
        const response = await updateNgrokURLInServer(ngrokURL);
        if (response === 'failed') {
            console.log('Failed to update nGrok URL');
            setLoading(false);
            return;
        }
        ngrokURLRef.current = ngrokURL;
        setLoading(false);
    }
    
    const nGrokURLMessage = 
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '700px'}}>
            <div><strong>Current nGrok URL:</strong> {ngrokURLRef.current} </div>
            <br/>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '32px'}}>
                <strong>{'New nGrok URL:   '}</strong> 
                <input 
                    style={{width: '550px', height: '32px', fontSize: '16px'}}
                    type='text' 
                    value={ngrokURL} 
                    onChange={(e)=>setNgrokURL(e.target.value)}
                />
            </div>    
        </div>

    console.log('ngrokURLRef.current:', ngrokURLRef.current);
    const nGrokButtons = [
        {text: 'Close', onClick: ()=>setIsNgrokURLOpen(false), width: 100},
        {text: 'Update', onClick: updateNgrokURL, width: 100}
    ];
    return (
        <ConfirmationModal message={nGrokURLMessage} buttons={nGrokButtons}/>
    )
}

