import React, { useContext, useState} from 'react';
import modalStyles from './ProviderQuoteDetails.module.css';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button'; 
import ItemQuotes from './ItemQuotes.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import ProviderBestQuoteDetails from './ProviderBestQuoteDetails.js';
import ConfirmationModal from 'GlobalComponents/ConfirmationModal/ConfirmationModal.js';
import { Quote, Provider } from 'Contexts/static_options/Classes.js'
import RemoveProviderButton from './RemoveProviderButton.js';
import ValidWhatsappButton from './ValidWhatsappButton.js';

export default function ProviderQuoteDetailsPopup({providerIndex}){
    const {setProviderQuoteDetails, currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    const [closeMessage, setCloseMessage] = useState(false);
    const [removeLinkResponseConfirmationIndex, setRemoveLinkResponseConfirmationIndex] = useState('');
    const [removeProviderConfirmationIndex, setRemoveProviderConfirmationIndex] = useState('');

    const close = () => {
        if(currentServiceOrder.providers[providerIndex].quoteViaLink && currentServiceOrder.currentStage==='notInitiated'){
            // test if every field is filled when there is a link response and we haven't initiated the process yet   // this is not testing for multiple items 
            const quoteViaLink = currentServiceOrder.providers[providerIndex].providerBestQuote;
            if(quoteViaLink.shortestAvailability.date===''){
                setCloseMessage('Preencha a data de disponibilidade informada pelo afiliado');
                return;
            }

            for(const itemQuote of currentServiceOrder.providers[providerIndex].itemQuotes){
                const itemMessage = `\n\n${currentServiceOrder.items[itemQuote.itemIndex].itemName} \nSKU: ${currentServiceOrder.items[itemQuote.itemIndex].SKU}`;

                if(itemQuote.price===0){
                    setCloseMessage(`Preencha o preço informado pelo afiliado para o item: ${itemMessage}`);
                    return;
                }
                if(itemQuote.shortestAvailability.availability===''){
                    setCloseMessage(`Preencha a disponibilidade informada pelo afiliado para o item: ${itemMessage}`);
                    return;
                }
                if(quoteViaLink.brand.tier===''){
                    setCloseMessage(`Preencha a marca informada pelo afiliado para o item: ${itemMessage}`);
                    return;
                }
            }
            if(currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO === ''){
                setCloseMessage('Preencha se o serviço é por ordem de chegada ou hora marcada');
                return;
            }
            if(currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.time === ''){
                setCloseMessage(currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO ? `Preencha o horário de abertura da loja do afiliado: ${currentServiceOrder.providers[providerIndex].providerName}` :'Preencha a hora marcada para o serviço');
                return;
            }
            markLinkQuoteAsCompleted(providerIndex)
        }
        setProviderQuoteDetails(false);
    }

    function markLinkQuoteAsCompleted(providerIndex){
        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers[providerIndex];
        newProvider.itemQuotes= newProvider.itemQuotes.map(itemQuote => ({...itemQuote, completed: true})),
        newProvider.providerBestQuote.itemQuotesIndexes = newProvider.itemQuotes.map((itemQuote, index) => index);
        newProvider.providerBestQuote.completed = true;
        setCurrentServiceOrder(newServiceOrder);
    }

    return(
        <Modal className={modalStyles.providerQuoteDetailsPopup} 
            isOpen ={currentServiceOrder.providers[providerIndex] ? true : false}
            onRequestClose={close}
            style={{
                overlay: {
                    backgroundColor: 'transparent', // Makes the overlay (backdrop) transparent
                }
            }}
        >
            <Button 
                className={modalStyles.closeButton}
                onClick={close} 
                style={{ position: 'absolute', top: '10px', right: '10px' }}
            >
                <CloseIcon />
            </Button>
            <div className={modalStyles.modalTitle}>Detalhes da cotação para {currentServiceOrder.providers[providerIndex].providerName}  </div>
            <div className={modalStyles.providerManagementButtons}>
                {currentServiceOrder.currentStage==="notInitiated" && <RemoveProviderButton providerIndex={providerIndex} setRemoveProviderConfirmationIndex={setRemoveProviderConfirmationIndex}/>}
                <ValidWhatsappButton providerIndex={providerIndex} />
            </div>
            <ProviderBestQuoteDetails providerIndex={providerIndex} setRemoveLinkResponseConfirmationIndex={setRemoveLinkResponseConfirmationIndex}/>
            <ItemQuotes providerIndex={providerIndex}/>
            {closeMessage && <ConfirmCloseModal closeMessage={closeMessage} setCloseMessage={setCloseMessage} providerIndex={providerIndex}/>}
            {removeLinkResponseConfirmationIndex!=='' && <RemoveLinkResponseConfirmation providerIndex={removeLinkResponseConfirmationIndex} setRemoveLinkResponseConfirmationIndex={setRemoveLinkResponseConfirmationIndex}/>}
            {removeProviderConfirmationIndex!=='' && <RemoveProviderConfirmation removeProviderConfirmationIndex={removeProviderConfirmationIndex} setRemoveProviderConfirmationIndex={setRemoveProviderConfirmationIndex} setProviderQuoteDetails={setProviderQuoteDetails}/>}
        </Modal>
    )
}


function ConfirmCloseModal({closeMessage, setCloseMessage, providerIndex}){
    const {setProviderQuoteDetails, currentServiceOrder} = useContext(QuoteMasterContext);

    const close = () => {
        setCloseMessage(false);
    }
    
    const ignoreAndClose = () => {
        setProviderQuoteDetails(false); 
        setCloseMessage(false);
    }

    const buttons = [
        {text: `Continuar Editando ${currentServiceOrder.providers[providerIndex].providerName}`, onClick: close, width: 200},
        {text: 'Ignorar e Fechar', onClick: ignoreAndClose, width: 200}
    ]
  
    return(
        <ConfirmationModal message={closeMessage} buttons={buttons}/>
    )
}

function RemoveLinkResponseConfirmation({providerIndex, setRemoveLinkResponseConfirmationIndex}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const removeLinkResponse = () => {
        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers[providerIndex];
        newProvider.providerBestQuote.bookingTime = {FIFO: '', time: ''};
        newProvider.providerBestQuote = new Quote();
        newProvider.quoteViaLink = false;
        newProvider.itemQuotes = [];
        setCurrentServiceOrder(newServiceOrder);
        setRemoveLinkResponseConfirmationIndex('');
    }

    const close = () => {
        setRemoveLinkResponseConfirmationIndex('');
    }

    const buttons = [
        {text: `Continuar Editando ${currentServiceOrder.providers[providerIndex].providerName}`, onClick: close, width: 176},
        {text: 'Remover Resposta ao Link', onClick: removeLinkResponse, width: 176}
    ]
  
    return(
        <ConfirmationModal message={'Deseja remover as informações de resposta ao link já preenchidas?'} buttons={buttons}/>
    )
}

function RemoveProviderConfirmation({removeProviderConfirmationIndex, setRemoveProviderConfirmationIndex, setProviderQuoteDetails }){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const removeProvider = () => {
        const newServiceOrder = {...currentServiceOrder};
        newServiceOrder.providers = newServiceOrder.providers.filter((provider, index) => index !== removeProviderConfirmationIndex);
        setProviderQuoteDetails(false)
        if(newServiceOrder.providers.length===0){ // this is the only provider - we need to create a new one after deleting this       
            newServiceOrder.providers[0] = new Provider('Fornecedor 1', '1111111');	
        } else if(newServiceOrder.bestQuoteProvider===currentServiceOrder.providers[removeProviderConfirmationIndex].affiliateId){ // this is the best quote provider
            newServiceOrder.bestQuoteProvider = newServiceOrder.providers[0].affiliateId;
        }

        
        setCurrentServiceOrder(newServiceOrder);
        setRemoveProviderConfirmationIndex('');
    }

    const close = () => {
        setRemoveProviderConfirmationIndex('');
    }

    console.log('removeProviderConfirmationIndex', removeProviderConfirmationIndex)

    const buttons = [
        {
            text: `Continuar Editando ${currentServiceOrder.providers[removeProviderConfirmationIndex].providerName}`,
            onClick: close, 
            width: 176
        },
        {
            text: 'Remover Afiliado',
            onClick: removeProvider,
            width: 176
        }
    ]
  
    return(
        <ConfirmationModal message={`Deseja remover o afiliado ${currentServiceOrder.providers[removeProviderConfirmationIndex].providerName} da ordem de serviço?\n Todas as informações relativas à este afiliado serão apagadas`} buttons={buttons}/>
    )
}
    