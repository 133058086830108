// FoldersToFileContext.js
import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import {Provider, Item, Client, freshTokensObject, Automobile, timeZone, desiredAvailability} from 'Contexts/static_options/Classes.js'

export const QuoteMasterContext = createContext();
export const QuoteMasterContextProvider = ({children}) => {
    const [user, setUser] = useState({userEmail: '', firstName: '', lastName: '', profilePic: '', userType: ''});
    
    const initialQuoteMasterMinimumRate = {rate: 5.0, price: ''};
    const initialDesirableRate = {rate: 7.0, price: ''};
    const initialMaximumRate = {rate: 10.0, price: ''};

    const initialProviders = [new Provider('Fornecedor 1', '1111111')] // this is the list of providers


    const initialTarget = {providerName: 'Target Atual', price: initialDesirableRate.price, rate:initialDesirableRate.rate , shortestAvailability: {days: desiredAvailability}, brand: {tier: 1}};
    const freshServiceOrder = {
        serviceOrderId: '',
        items: [new Item()], // this is the list of items to be negotiated
        linkForDamagePictures: '', // this is the list of pictures of the damage
        providers: initialProviders,
        clientInformation: new Client(), // this is the information of the client
        serviceAddress: {city: '', state: '', suburb: '', latitude:'', longitude: ''}, // this is the address of the service
        autoInformation: new Automobile(), // this is the information of the automobile
        runType: 'regular',
        quoteMasterMinimumRate: initialQuoteMasterMinimumRate,
        minimumDesirableRate: initialDesirableRate,
        maximumRate: initialMaximumRate,
        desiredAvailability: {days: desiredAvailability, date: moment().tz(timeZone).add(desiredAvailability, 'days').format('YYYY-MM-DD')},
        maximumRateDiscount: 0.05,
        bestQuoteProvider: initialProviders[0].affiliateId, // this is the provider ID of the best quote
        currentTarget: initialTarget,
        totalTime: 9, // this is the total time for the whole negotiation
        followUpTime: 1, // this is the time between follow ups
        stageTime: 3, // this is the time for each stage
        currentStage: 'notInitiated', // this is the status of the negotiation
        previousStage: '', // this is the last stage of the negotiation
        timeMultiplier: 3600, // this is the multiplier for the time in each stage in seconds. for the numbers stageTime, followUpTime and totalTime  be in hours, the timeMultiplier should be 3600
        stageStartTime: [{stage: 'notInitiated', time: ''},{stage: 1, time: ''}, {stage: 2, time: ''}, {stage: 3, time: ''}, {stage: 'confirmToClose', time: ''}, {stage: 'confirmFifoAndTime', time: ''}, {stage: 'confirmDates', time: ''}, {stage: 'fallbackToHuman', time: ''}, {stage: 'closed', time: ''}], // this is the time when each stage started
        timeLastUpdate: '', // this is the time when the service order was last updated
        closeReason: '', // this is the reason for closing the service order
        tokens: [],
        virtualAgent: {agentName: '', agentCity:'', agentState: '', agentWeatherDescription: ''},
        user: user,
        orderPenalty: 3, // days to add to the relative days if the item is not in stock
        tier2Penalty: 7, // days to add to the relative days if the item is tier 2
        notes: '',
        hashtags: [],
        mode: process.env.REACT_APP_NODE_ENV === 'development' ? 'development' : 'production',
    }

    const [currentServiceOrder, setCurrentServiceOrder] = useState({...freshServiceOrder, user: user});
    const [loading, setLoading] = useState(false);
    const [OSTabOpen, setOSTabOpen] = useState(false);
    const [providerQuoteDetails, setProviderQuoteDetails] = useState(false);
    const [serviceOrders, setServiceOrders] = useState([]);
    const [SOToDelete, setSOToDelete] = useState(null);
    const [providersList, setProvidersList] = useState([]);
    const [providersReport, setProvidersReport] = useState([]);
    
    useEffect(() => {
        if(user && user.userEmail){
            setCurrentServiceOrder({...currentServiceOrder, user: user});
        }
    }, [user]);

    const userOptions = [
        {value:'admin', label: 'Admin'},
        {value:'agent', label: 'Negociador'},
        {value:'master-admin', label:"Admin Master"},
        {value:'read-only', label: "Apenas Leitura"}
    ];

    const contextValue = {
        currentServiceOrder, setCurrentServiceOrder,
        freshServiceOrder,
        desiredAvailability, 
        initialTarget, 
        loading, setLoading,
        OSTabOpen, setOSTabOpen,
        providersList, setProvidersList,
        providerQuoteDetails, setProviderQuoteDetails,
        providersReport, setProvidersReport,
        SOToDelete, setSOToDelete,
        serviceOrders, setServiceOrders, 
        userOptions,
        user, setUser, 
    };
        
    return (
        <QuoteMasterContext.Provider value={contextValue}>
            {children}
        </QuoteMasterContext.Provider>
    );
};

